import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const STORE_COMMITTEE = "store-committee";
export const UPDATE_COMMITTEE = "update-committee";
export const DELETE_COMMITTEE = "delete-committee";
export const DELETE_TRAINING_VIDEO = "delete-training-video";
export const DELETE_TASK = "delete-tasks";
export const DELETE_MEMBER_COMMITTEE = "delete-member-committee";
export const ADD_TO_COMMITTEE = "add-to-committee";
export const TASK_MARK_COMPLETED = "task-mark-completed";
export const STORE_ROLE = "store-role";
export const UPDATE_ROLE = "update-role";
export const DELETE_ROLE = "delete-role";
export const DELETE_EMPLOYEE_PAYMENT = "delete-employee-payment";
export const SEND_PAYMENT_LINK = "send-payment-link";
export const STORE_PAYMENT = "store-payment";
export const UPDATE_PERMISSIONS = "update-permissions";
export const UPDATE_SETTINGS_ADD_BUSINESS_ACCOUNT = "update-settings-add-business-account";
export const UPDATE_SETTINGS_TRAINING = "update-settings-training";

// mutation types
export const SET_ERROR = "setError";
export const SET_COMMITTEE = "setCommittee";

const state = {
  errors: null,
  committee: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {};

const actions = {
  [DELETE_ROLE](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("committee-roles/" + id, {})
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("DELETE_ROLE", true);
          }
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Role Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_ROLE](context, role) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("committee-roles/" + role.id, role)
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("UPDATE_ROLE", true);
          }
        })
        .catch(() => {
          Swal.fire("Error", "Error in Role Update", "error");
          EventBus.$emit("UPDATE_ROLE", false);
          EventBus.$emit("UPDATE_ROLE_ERROR", true);
        });
    });
  },
  [STORE_ROLE](context, role) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("committee-roles", role)
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("STORE_ROLE", true);
          }
        })
        .catch(() => {
          EventBus.$emit("STORE_ROLE", false);
        });
    });
  },
  [TASK_MARK_COMPLETED](context, task_user) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("update-member-committe", task_user)
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0){
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("ADD_TO_COMMITTEE", true);
          }
        })
        .catch(() => {
          EventBus.$emit("ADD_TO_COMMITTEE", true);
        });
    });
  },
  [ADD_TO_COMMITTEE](context, committee) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("store-committee-task", committee)
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0){
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("ADD_TO_COMMITTEE", true);
          }
        })
        .catch(() => {
          EventBus.$emit("ADD_TO_COMMITTEE", true);
        });
    });
  },
  [STORE_PAYMENT](context, payment) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("employee-payment", payment)
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0){
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("STORE_PAYMENT", data.employeePayment);
          }
        })
        .catch(() => {
          EventBus.$emit("STORE_PAYMENT", true);
        });
    });
  },
  [STORE_COMMITTEE](context, committee) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("committee", committee)
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Success", data.message, "success");
          EventBus.$emit("STORE_COMMITTEE", true);
        })
        .catch(() => {
          EventBus.$emit("STORE_COMMITTEE", true);
        });
    });
  },
  [UPDATE_COMMITTEE](context, committee) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("committee/" + committee.id, committee)
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Success", data.message, "success");
          EventBus.$emit("UPDATE_COMMITTEE", true);
        })
        .catch(() => {
          Swal.fire("Error", "Error in Committee Update", "error");
          EventBus.$emit("UPDATE_COMMITTEE", false);
          EventBus.$emit("UPDATE_COMMITTEE_ERROR", true);
        });
    });
  },
  [DELETE_COMMITTEE](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("committee/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Committee is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Committee Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_MEMBER_COMMITTEE](context, user_committee_ids) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("delete-member-committee", user_committee_ids)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Contact Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_TASK](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("assignment_task/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Task is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Task Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_TRAINING_VIDEO](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("training-video/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Training video is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Training Video Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_EMPLOYEE_PAYMENT](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("employee-payment/"+id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Payment Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [SEND_PAYMENT_LINK](context, payment_id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("send-payment-link",payment_id)
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("DELETE_EMPLOYEE_PAYMENT", true);
          }
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Contact Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_PERMISSIONS](context, payload) {
    var role_id = payload.role_id;
    var permission_ids = payload.permissions_ids;
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("update-role-permissions", {role_id, permission_ids})
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("UPDATE_PERMISSIONS", true);
          }
        })
        .catch(() => {
          EventBus.$emit("UPDATE_PERMISSIONS", false);
        });
    });
  },
  [UPDATE_SETTINGS_ADD_BUSINESS_ACCOUNT](context, payload) {

    var period_add_business_account_notification = payload.period_add_business_account_notification;
    var period_add_business_account_notification_date = payload.period_add_business_account_notification_date;

    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("settings", {period_add_business_account_notification,period_add_business_account_notification_date})
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("UPDATE_SETTINGS_ADD_BUSINESS_ACCOUNT", true);
          }
        })
        .catch(() => {
          EventBus.$emit("UPDATE_SETTINGS_ADD_BUSINESS_ACCOUNT", false);
        });
    });
  },
  [UPDATE_SETTINGS_TRAINING](context, payload) {

    var period_complete_training_notification = payload.period_complete_training_notification;
    var period_complete_training_notification_date = payload.period_complete_training_notification_date;

    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("settings", {period_complete_training_notification,period_complete_training_notification_date})
        .then(({ data }) => {
          resolve(data);
          if(data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("UPDATE_SETTINGS_TRAINING", true);
          }
        })
        .catch(() => {
          EventBus.$emit("UPDATE_SETTINGS_TRAINING", false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
