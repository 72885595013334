import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
// import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const GET_TAGS = "get-tags";
export const GET_REMINDER_DAYS = "get-reminder-days";
export const GET_DOCUMENT_TYPES = "get-document-types";

// mutation types
export const SET_TAGS = "setTags";
export const SET_REMINDER_DAYS = "setReminderDays";
export const SET_DOCUMENT_TYPES = "setDocumentTypes";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  usersTags(state) {
    return state.tags;
  },
  reminderDays(state) {
    return state.reminder_days;
  },
  documentTypes(state) {
    return state.document_types;
  }
};

const actions = {
  [GET_TAGS](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("get-tags", {})
        .then(({ data }) => {
          context.commit(SET_TAGS, data.tags);
          resolve(data);
        })
        .catch(({ response }) => {
          // Swal.fire("Error", "Error in fetcing tags.", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_REMINDER_DAYS](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("get-reminder-days", {})
        .then(({ data }) => {
          context.commit(SET_REMINDER_DAYS, data.reminder_days);
          resolve(data);
        })
        .catch(({ response }) => {
          // Swal.fire("Error", "Error in fetching reminder days.", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_DOCUMENT_TYPES](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("document-types?search=''", "")
        .then(({ data }) => {
          context.commit(SET_DOCUMENT_TYPES, data.document_types);
          resolve(data);
        })
        .catch(({ response }) => {
          // Swal.fire("Error", "Error in fetching Documents Types", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_TAGS](state, tags) {
    state.tags = tags;
  },
  [SET_REMINDER_DAYS](state, reminders) {
    state.reminder_days = reminders;
  },
  [SET_DOCUMENT_TYPES](state, types) {
    state.document_types = types;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
