import GuestApiService from "@/core/services/guestapi.service";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";

// action types
export const GUEST_STORE_ANSWER = "guest-store-answer";
export const GUEST_STORE_ASSESSMENT_RESULT = "guest-store-assessment-result";
export const GUEST_CANCEL_ASSESSMENT_EXIT = "guest-cencel-assessment-exit";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null
};

const getters = {
  
};

const actions = {
  [GUEST_CANCEL_ASSESSMENT_EXIT](context, assessment) {
    return new Promise(resolve => {
      GuestApiService.init("askcoda");
      GuestApiService.post("guest-cancel-assessment", assessment)
        .then(({ data }) => {
          resolve(data);
          //EventBus.$emit("CANCEL_ASSESSMENT_EXIT", true);
        })
        .catch(() => {
          //EventBus.$emit("CANCEL_ASSESSMENT_EXIT", false);
        });
    });
  },
  
  [GUEST_STORE_ANSWER](context, answer) {
    return new Promise(resolve => {
      GuestApiService.init("askcoda");
      GuestApiService.post("guest-user-assessment-answer", answer)
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("STORE_GUEST_ASSESSMENT_ANSWER", true);
        })
        .catch(() => {
          EventBus.$emit("STORE_GUEST_ASSESSMENT_ANSWER", false);
        });
    });
  },
  [GUEST_STORE_ASSESSMENT_RESULT](context, assessment) {
    return new Promise(resolve => {
      GuestApiService.init("askcoda");
      GuestApiService.post("guest-user-assessment-final-score", assessment)
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("STORE_GUEST_ASSESSMENT_SCORE", data);
        })
        .catch(() => {
          EventBus.$emit("STORE_GUEST_ASSESSMENT_SCORE", false);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
