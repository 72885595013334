import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import EventBus from "@/core/services/store/event-bus";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const GET_BUSINESS_ACCOUNTS = "get-business-account";
export const STORE_BUSINESS_ACCOUNT = "store-business-account";
export const UPDATE_BUSINESS_ACCOUNT = "update-business-account";
export const DELETE_BUSINESS_ACCOUNT = "delete-business-account";
export const GET_SUBSCRIPTION_DURATION = "get-subscription-duration";

// mutation types
export const SET_ERROR = "setError";
export const SET_BA = "setBusinessAccounts";
export const SET_SUBSCRIPTION_DURATION = "setSubscriptionDuration";

const state = {
  errors: null,
  business_account: {},
  isAuthenticated: !!JwtService.getToken(),
  duration: ""
};

const getters = {
  getStateSubscriptionDuration(state) {
    return state.duration;
  },
  getBusinessAccount(state) {
    return state.business_account;
  }
};

const actions = {
  [GET_BUSINESS_ACCOUNTS](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("business-account?search=''", "")
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_BA, data.business_account);
          EventBus.$emit("BA_COUNT", data.business_account.length);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in fetch Business Account", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_BUSINESS_ACCOUNT](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("business-account/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Business Account is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Business Account Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_BUSINESS_ACCOUNT](context, business_account) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("business-account", { business_account })
        .then(({ data }) => {
          Swal.fire(
            "Stored",
            "Business Account added successfully.",
            "success"
          );
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Business Account store", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_BUSINESS_ACCOUNT](context, business_account) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("business-account/" + business_account.id, {
        business_account
      })
        .then(({ data }) => {
          Swal.fire("Updated", data.message, "success");
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Business Account Update", "error");
          context.commit(SET_ERROR, response);
          EventBus.$emit("UPDATE_BUSINESS_ACCOUNT_ERROR", true);
        });
    });
  },
  [GET_SUBSCRIPTION_DURATION](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("get-subscription-duration")
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_SUBSCRIPTION_DURATION, data.subscription_duration);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Subscription Duration list.", "error");
          context.commit(SET_ERROR, response);
        });
    });
  }
};

const mutations = {
  [SET_SUBSCRIPTION_DURATION](state, duration) {
    state.duration = duration;
  },
  [SET_BA](state, business_account) {
    state.business_account = business_account;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
