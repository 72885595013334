import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const STORE_CONTACT = "store-contact";
export const UPDATE_CONTACT = "update-contact";
export const DELETE_CONTACT = "delete-contact";
export const LIST_CONTACTS = "contact-list";

// mutation types
export const SET_ERROR = "setError";
export const SET_CONTACTS = "setContacts";

const state = {
  errors: null,
  contacts: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  getContactsList(state) {
    return state.contacts;
  }
};

const actions = {
  [STORE_CONTACT](context, contact) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("contacts", contact)
        .then(({ data }) => {
          resolve(data);
          if (data.status == 0) {
            Swal.fire("Error", data.message, "error");
            EventBus.$emit("STORE_CONTACT", false);
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("STORE_CONTACT", true);
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          EventBus.$emit("STORE_CONTACT", false);
        });
    });
  },
  [UPDATE_CONTACT](context, contact) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("contacts/" + contact.id, contact)
        .then(({ data }) => {
          resolve(data);
          if (data.status == 0) {
            Swal.fire("Error", data.message, "error");
            EventBus.$emit("STORE_CONTACT", false);
          } else {
            Swal.fire("Success", data.message, "success");
            EventBus.$emit("STORE_CONTACT", true);
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          Swal.fire("Error", "Error in Contact Update", "error");
          EventBus.$emit("STORE_CONTACT", true);
          EventBus.$emit("UPDATE_CONTACT_ERROR", true);
        });
    });
  },
  [DELETE_CONTACT](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("contacts/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Contact is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Contact Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [LIST_CONTACTS](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("contacts")
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_CONTACTS, data.contacts);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Contact list", "error");
          context.commit(SET_ERROR, response);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CONTACTS](state, contacts) {
    state.contacts = contacts;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
