import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import EventBus from "@/core/services/store/event-bus";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const GET_RESOURCE_TYPES = "get-resource-type";
export const STORE_RESOURCE_TYPE = "store-resource-type";
export const UPDATE_RESOURCE_TYPE = "update-resource-type";
export const DELETE_RESOURCE_TYPE = "delete-resource-type";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  resource_type: {},
  isAuthenticated: !!JwtService.getToken(),
  duration: ""
};

const getters = {
  getResourceType(state) {
    return state.resource_type;
  }
};

const actions = {
  [GET_RESOURCE_TYPES](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("resource-type?search=''", "")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in fetch Resource", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_RESOURCE_TYPE](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("resource-types/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Resource Type is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Resource Type Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_RESOURCE_TYPE](context, resource_type) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("resource-types", { resource_type })
        .then(({ data }) => {
          resolve(data);
          if (data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Stored", data.message, "success");
          }
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Resource Type store", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_RESOURCE_TYPE](context, resource_type) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("resource-types/" + resource_type.id, {
        resource_type
      })
        .then(({ data }) => {
          resolve(data);
          if (data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Updated", data.message, "success");
          }
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Resource Type Update", "error");
          context.commit(SET_ERROR, response);
          EventBus.$emit("UPDATE_RESOURCE_TYPE_ERROR", true);
        });
    });
  }
};

const mutations = {  };

export default {
  state,
  actions,
  mutations,
  getters
};
