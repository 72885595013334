import ApiAcService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EventBus from "@/core/services/store/event-bus";
// import { SET_ACCOUNT_INFO, SET_PERSONAL_INFO } from "./profile.module";
import Swal from "sweetalert2";

// action types
export const GET_COMPANY = "company";
export const GET_USER = "user";
export const STORE_COMPANY = "store_company";
export const GET_COMPANY_USERS = "get-company-users";
export const GET_COUNTRIES = "get-countries";
export const GET_STATES = "get-states";
export const GET_ORG_TYPES = "get-company-users";
export const CANCEL_SUBSCRIPTION = "cancelSubscription";

// mutation types
export const SET_COMPANY = "setCompany";
export const SET_USER = "setUser";
export const SET_AUTH_USER = "setAuthUser";
export const SET_ERROR = "setError";
export const SET_COMPANY_USERS = "setCompanyUsers";
export const SET_COUNTRIES = "setCountries";
export const SET_STATES = "setStates";
export const SET_ORG_TYPES = "setOrgTypes";
export const SET_PERMISSIONS = "setPermissions";
export const SET_USER_PERMISSIONS = "setUserPermissions";
export const SET_SUBSCRIPTION_TYPE = "setSubscriptionType";
export const SET_ROLE_NAME = "setRoleName";
export const SET_IS_COMPANY_ADMIN = "setIsCompanyAdmin";
export const SET_FEATURES = "setFeatures";
export const SET_SUBSCRIPTION = "setSubscription";
export const SET_SUBSCRIPTION_ID = "setSubscriptionId";
export const SET_PAYMENT_STATUS = "setPaymentStatus";

const state = {
  subscription_type: "",
  roleName: "",
  subscriptionId: "",
  paymentStatus: "",
  errors: null,
  user: {},
  auth_user: {},
  company: {},
  permissions: [],
  user_permissions: [],
  subscription: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentCompany(state) {
    return state.user;
  },
  companyUsers(state) {
    return state.company_users;
  },
  getCompany(state) {
    return state.company;
  },
  getAuthUser(state) {
    return state.auth_user;
  },
  getUser(state) {
    return state.auth_user;
  },
  getFeatures(state) {
    return state.features;
  },
  getPermissions(state) {
    return state.permissions;
  },
  getUserPermissions(state) {
    return state.user_permissions;
  },
  getSubscriptionType(state) {
    return state.subscription_type;
  },
  getRoleName(state) {
    return state.roleName;
  },
  getIsCompanyAdmin(state) {
    return state.is_company_admin;
  },
  getSubscription(state) {
    return state.subscription;
  },
  getSubscriptionId(state) {
    return state.subscriptionId;
  },
  getPaymentStatus(state) {
    return state.paymentStatus;
  },
  getCountries(state) {
    return state.countries;
  },
  getStates(state) {
    return state.states;
  },
  getOrganizatonTypes(state) {
    return state.organization_types;
  }
};

const actions = {
  [STORE_COMPANY](context, payload) {
    return new Promise(resolve => {
      ApiAcService.init("askcoda");
      ApiAcService.post("store_company", payload)
        .then(({ data }) => {
          // console.log("Here what post returns", JSON.stringify(data));
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Store company error!", "error");

          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COMPANY](context) {
    return new Promise(resolve => {
      ApiAcService.init("askcoda");
      ApiAcService.post("get-company")
        .then(({ data }) => {
          context.commit(SET_COMPANY, data.company);
          context.commit(SET_AUTH_USER, data.user);
          context.commit(SET_PERMISSIONS, data.permissions);
          context.commit(SET_USER_PERMISSIONS, data.user_permissions);
          context.commit(SET_FEATURES, data.features);
          context.commit(SET_SUBSCRIPTION, data.subscription);
          context.commit(SET_SUBSCRIPTION_ID, data.subscriptionId);
          context.commit(SET_PAYMENT_STATUS, data.paymentStatus);
          context.commit(SET_SUBSCRIPTION_TYPE, data.subscription_type);
          context.commit(SET_ROLE_NAME, data.roleName);
          context.commit(SET_IS_COMPANY_ADMIN, data.user.is_company_admin);

          EventBus.$emit("GET_COMPANY", data.company);
          EventBus.$emit("GET_USER", data.user);
          EventBus.$emit("GET_FEATURES", data.features);
          EventBus.$emit("GET_SUBSCRIPTION_TYPE", data.subscription_type);
          EventBus.$emit("GET_SUBSCRIPTION", data.subscription);
          EventBus.$emit("GET_SUBSCRIPTION_ID", data.subscriptionId);
          EventBus.$emit("GET_ROLE_NAME", data.roleName);
          EventBus.$emit("GET_PAYMENT_STATUS", data.paymentStatus);
          EventBus.$emit("GET_IS_COMPANY_ADMIN", data.user.is_company_admin);
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Get company error!", "error");

          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_USER](context) {
    return new Promise(resolve => {
      ApiAcService.init("askcoda");
      ApiAcService.post("get-company")
        .then(({ data }) => {
          context.commit(SET_USER, data.user);
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Get User error!", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COMPANY_USERS](context, company_id) {
    return new Promise(resolve => {
      ApiAcService.init("askcoda");
      ApiAcService.get("get-company-users", company_id)
        .then(({ data }) => {
          context.commit(SET_COMPANY_USERS, data.company_users);
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Company user fetch error!", "error");

          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_COUNTRIES](context) {
    return new Promise(resolve => {
      ApiAcService.init("auth");
      ApiAcService.post("get-countries")
        .then(({ data }) => {
          context.commit(SET_COUNTRIES, data.countries);
          resolve(data);
        })
        .catch(({ response }) => {
          // Swal.fire("Error", "Countries fetch error!", "error");

          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_STATES](context) {
    return new Promise(resolve => {
      ApiAcService.init("auth");
      ApiAcService.post("get-states")
        .then(({ data }) => {
          context.commit(SET_STATES, data.states);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_ORG_TYPES](context) {
    return new Promise(resolve => {
      ApiAcService.init("askcoda");
      ApiAcService.post("get-organization-types")
        .then(({ data }) => {
          context.commit(SET_ORG_TYPES, data.organization_types);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [CANCEL_SUBSCRIPTION](context, subscription_id) {
    return new Promise(resolve => {
      ApiAcService.init("askcoda");
      ApiAcService.post("cancel-subscription", subscription_id)
        .then(({ data }) => {
            resolve(data);
            if(data.status == 0) {
              Swal.fire("Error", data.message, "error");
            } else {
              Swal.fire("Success", data.message, "success");
            }
            EventBus.$emit("CANCEL_SUBSCRIPTION", data);
        })
        .catch(({ response }) => {
          EventBus.$emit("CANCEL_SUBSCRIPTION", false);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_COMPANY_USERS](state, users) {
    state.company_users = users;
  },
  [SET_COMPANY](state, company) {
    state.company = company;
  },
  [SET_AUTH_USER](state, auth_user) {
    state.auth_user = auth_user;
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },
  [SET_USER_PERMISSIONS](state, user_permissions) {
    state.user_permissions = user_permissions;
  },
  [SET_FEATURES](state, features) {
    state.features = features;
  },
  [SET_SUBSCRIPTION](state, subscription) {
    state.subscription = subscription;
  },
  [SET_SUBSCRIPTION_ID](state, subscriptionId) {
    state.subscriptionId = subscriptionId;
  },
  [SET_PAYMENT_STATUS](state, paymentStatus) {
    state.paymentStatus = paymentStatus;
  },
  [SET_SUBSCRIPTION_TYPE](state, subscription_type) {
    state.subscription_type = subscription_type;
  },
  [SET_ROLE_NAME](state, roleName) {
    state.roleName = roleName;
  },
  [SET_IS_COMPANY_ADMIN](state, is_company_admin){
    state.is_company_admin = is_company_admin;
  },
  [SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },
  [SET_STATES](state, states) {
    state.states = states;
  },
  [SET_ORG_TYPES](state, org_types) {
    state.organization_types = org_types;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
