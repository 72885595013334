import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const GET_ASSESSMENTS = "assessment-list";
export const GET_ASSESSMENT_SCORE = "get-assessment-score";
export const GET_COMPLETED_ASSESSMENTS = "get-completed-assessment";
export const STORE_ANSWER = "store-answer";
export const STORE_ASSESSMENT_RESULT = "store-assessment-result";
export const SAVE_ASSESSMENT_EXIT = "save-assessment-exit";
export const CANCEL_ASSESSMENT_EXIT = "cencel-assessment-exit";

// mutation types
export const SET_ERROR = "setError";
export const SET_ASSESSMENTS = "setAssessments";
export const SET_COMPLETED_ASSESSMENTS = "setCompletedAssessments";

const state = {
  errors: null,
  assessments: null,
  completed_assessments: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  getAssessmentsList(state) {
    return state.assessments;
  },
  getCompletedAssessments(state) {
    return state.completed_assessments;
  }
};

const actions = {
  [SAVE_ASSESSMENT_EXIT](context, assessment) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("pause-assessment", assessment)
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("SAVE_ASSESSMENT_EXIT", true);
        })
        .catch(() => {
          EventBus.$emit("SAVE_ASSESSMENT_EXIT", false);
        });
    });
  },
  [CANCEL_ASSESSMENT_EXIT](context, assessment) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("cancel-assessment", assessment)
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("CANCEL_ASSESSMENT_EXIT", true);
        })
        .catch(() => {
          EventBus.$emit("CANCEL_ASSESSMENT_EXIT", false);
        });
    });
  },
  [GET_COMPLETED_ASSESSMENTS](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("complete-assessments")
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_ASSESSMENTS, data.assessments);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in completed Assessment list", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_ASSESSMENTS](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("/assessments?search=''", "")
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("ASSESSMENT_LIST", data.assessments);
          context.commit(SET_COMPLETED_ASSESSMENTS, data.assessments);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Assessment list", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_ANSWER](context, answer) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("user-assessment-answer", answer)
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("STORE_ASSESSMENT_ANSWER", true);
        })
        .catch(() => {
          EventBus.$emit("STORE_ASSESSMENT_ANSWER", false);
        });
    });
  },
  [STORE_ASSESSMENT_RESULT](context, assessment) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("user-assessment-final-score", assessment)
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("STORE_ASSESSMENT_SCORE", data);
        })
        .catch(() => {
          EventBus.$emit("STORE_ASSESSMENT_SCORE", false);
        });
    });
  },
  [GET_ASSESSMENT_SCORE](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("get-assessment-score?search=''", "")
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("SET_ASSESSMENT_SCORE", data.risk_score);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in completed Assessment list", "error");
          context.commit(SET_ERROR, response);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ASSESSMENTS](state, assessments) {
    state.assessments = assessments;
  },
  [SET_COMPLETED_ASSESSMENTS](state, assessments) {
    state.completed_assessments = assessments;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
