import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const DELETE_RESOURCE = "delete-resource";
export const GET_RESOURCE_TYPES = "get-resource-types";

// mutation types
export const SET_ERROR = "setError";
export const SET_RESOURCE_TYPES = "set-resource-types";

const state = {
  errors: null,
  resource: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  resourceTypes(state) {
    return state.resource_types;
  }
};

const actions = {
  [DELETE_RESOURCE](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("resource/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Resource file is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Resource Delete", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_RESOURCE_TYPES](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("get-resource-types", {})
        .then(({ data }) => {
          context.commit(SET_RESOURCE_TYPES, data.resource_types);
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in fetching Resource Types", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_RESOURCE_TYPES](state, types) {
    state.resource_types = types;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
