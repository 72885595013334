import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import EventBus from "@/core/services/store/event-bus";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const STORE_DOCUMENT = "store-document";
export const GET_DOCUMENT_LIST = "get-document-list";
export const GET_DOCUMENT_BY_ID = "get-document-by-id";
export const DELETE_DOCUMENT = "delete-document";

// mutation types
export const SET_DOCUMENT_LIST = "setDocumentList";
export const SET_DOCUMENT_OBJ = "setDocumentObj";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  documents: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  stateDocumentsList(state) {
    return state.documents;
  },
  stateDocumentObj(state) {
    return state.document_obj;
  }
};

const actions = {
  // Get we cannot pass it in the STORE because it will not change the value
  [GET_DOCUMENT_LIST](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("document?search=''", "")
        .then(({ data }) => {
          context.commit(SET_DOCUMENT_LIST, data.documents);
          EventBus.$emit("DOCUMENTS_COUNT", data.documents.length);
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Document List", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_DOCUMENT_BY_ID](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("document", id)
        .then(({ data }) => {
          context.commit(SET_DOCUMENT_OBJ, data.document);
          resolve(data);
        })
        .catch(response => {
          Swal.fire("Error", "Error in Document get by id", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [DELETE_DOCUMENT](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("document/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Document file is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Document Delete", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  //store document is not working here its called from the Documents.vue file
  [STORE_DOCUMENT](context, document) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("store-document", { document })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Document Store", "error");
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  }
};

const mutations = {
  [SET_DOCUMENT_LIST](state, document_list) {
    state.documents = document_list;
  },
  [SET_DOCUMENT_OBJ](state, document_obj) {
    state.document_obj = document_obj;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
