import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_ACCOUNT_INFO, SET_PERSONAL_INFO } from "./profile.module";
import Swal from "sweetalert2";
import EventBus from "@/core/services/store/event-bus";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const OTP = "verify-otp";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const UPDATE_BANK_DETAILS = "updateBankDetails";
export const RESET_PASSWORD = "updatePassword";
export const VERIFY_USER = "verifyUser";
export const VERIFY_COMPANY_ADMIN = "verifyCompanyAdmin";
export const FORGOT_PASSWORD = "forgotPassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.init("auth");
      ApiService.post("login", credentials)
        .then(({ data }) => {
          // console.log("Here what post returns", JSON.stringify(data));
          // context.commit(SET_AUTH, data);

          console.log("AUTH")
          console.log(data)
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "",
            text: response.data.errors,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [OTP](context, credentials) {
    return new Promise(resolve => {
      ApiService.init("auth");
      ApiService.post("verify-otp", credentials)
      .then(({ data }) => {
        context.commit(SET_AUTH, data);
        console.log("OTP")
        console.log(data);
        resolve(data);
      })
      .catch(({ response }) => {
        console.log("otp error called");
        Swal.fire({
          title: "",
          text: "Please Provide Correct Otp!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
        context.commit(SET_ERROR, response);
      });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [VERIFY_USER](context, user_detail) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.post("user/verify", user_detail)
        .then(({ data }) => {
          Swal.fire({
            title: "Onboarded",
            text: data.message,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          EventBus.$emit("USER_VERIFY", true);
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "",
            text: "Error in update member. Please try again.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          EventBus.$emit("USER_VERIFY", false);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_COMPANY_ADMIN](context, user_detail) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.post("company-admin/verify", user_detail)
        .then(({ data }) => {
          Swal.fire({
            title: "Onboarded",
            text: data.message,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          EventBus.$emit("COMPANY_ADMIN_VERIFY", true);
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "",
            text: "Error in verify email. Please try again.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          EventBus.$emit("COMPANY_ADMIN_VERIFY", false);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.init("auth");
      ApiService.post("register", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.init("auth");
      ApiService.setHeader();
      ApiService.post("verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          //set user and account informations
          context.commit(SET_ACCOUNT_INFO, data.account_info);
          context.commit(SET_PERSONAL_INFO, data.user_info);
        })
        .catch(({ response }) => {
          context.commit(PURGE_AUTH);
          // context.$router.push({ name: "login" });
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [RESET_PASSWORD](context, payload) {
    ApiService.init("auth");
    return ApiService.post("reset-password", payload)
      .then(({ data }) => {
        if (data.message) {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
        context.commit(PURGE_AUTH);
        context.commit(SET_ERROR, response.data.errors);
      });
  },
  [FORGOT_PASSWORD](context, payload) {
    return new Promise(resolve => {
      ApiService.init("auth");
      ApiService.post("forgot-password", payload)
        .then(({ data }) => {
            resolve(data);
            if(data.status == 0) {
              Swal.fire("Error", data.message, "error");
            } else {
              Swal.fire("Success", data.message, "success");
              EventBus.$emit("FORGOT_PASSWORD", true);
            }
        })
        .catch(({ response }) => {
            EventBus.$emit("FORGOT_PASSWORD", false);
        });
    });
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", { password: password }).then(data => {
      Swal.fire("Success", data.data.message, "success");
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [UPDATE_BANK_DETAILS](context, payload) {
    const bank_detail = payload;
    ApiService.init("askcoda");
    return ApiService.put("bank-details", { bank_detail: bank_detail }).then(data => {
      Swal.fire("Success", data.data.message, "success");

      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
