import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const STORE_ENROLL_EMPLOYEES = "store-enroll-employee-us";
export const UPDATE_TRAINING_DATES = "update-training-dates";
export const MARK_TRAINING_COMPLETED = "mark-training-completed";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  training: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {};

const actions = {
  [MARK_TRAINING_COMPLETED](context, request_params) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("mark-training-completed", request_params)
        .then(({ data }) => {
          resolve(data);
          if(data.status){
            //EventBus.$emit("MARK_TRAINING_COMPLETED", true);
            Swal.fire("Success", data.message, "success");
          } else {
            //EventBus.$emit("MARK_TRAINING_COMPLETED", false);
            Swal.fire("Error", data.message, "error");
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          //EventBus.$emit("MARK_TRAINING_COMPLETED", false);
          Swal.fire("Error", "Error in employee enroll. Please try again.", "error");
        });
    });
  },
  [UPDATE_TRAINING_DATES](context, request_params) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("update-training-dates", request_params)
        .then(({ data }) => {
          resolve(data);
          if(data.status){
            EventBus.$emit("UPDATE_TRAINING_DATES", true);
          } else {
            EventBus.$emit("UPDATE_TRAINING_DATES", false);
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          EventBus.$emit("UPDATE_TRAINING_DATES", false);
        });
    });
  },
  [STORE_ENROLL_EMPLOYEES](context, employee_ids) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("enroll-employees", employee_ids)
        .then(({ data }) => {
          resolve(data);
          if(data.status){
            //EventBus.$emit("STORE_ENROLL_EMPLOYEES", true);
            Swal.fire("Success", data.message, "success");
          } else {
            //EventBus.$emit("STORE_ENROLL_EMPLOYEES", false);
            Swal.fire("Error", data.message, "error");
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          //EventBus.$emit("STORE_ENROLL_EMPLOYEES", false);
          Swal.fire("Error", "Error in employee enroll. Please try again.", "error");
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
