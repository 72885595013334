import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const STORE_EMPLOYEE = "store-employee";
export const UPDATE_EMPLOYEE = "update-employee";
export const DELETE_EMPLOYEE = "delete-employee";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  employees: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {};

const actions = {
  [DELETE_EMPLOYEE](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("employee/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Employee is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Employee Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_EMPLOYEE](context, employee) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("employee", { employee })
        .then(({ data }) => {
          if (data.status == 0) {
            Swal.fire("Not Valid", data.message, "error");
            EventBus.$emit("STORE_EMPLOYEE_BUS", false);
          } else {
            Swal.fire("Stored", "Employee added successfully.", "success");
            EventBus.$emit("STORE_EMPLOYEE_BUS", true);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Employee Store", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_EMPLOYEE](context, employee) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("employee/" + employee.id, { employee })
        .then(({ data }) => {
          Swal.fire("Updated", data.message, "success");
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Employee Update", "error");
          context.commit(SET_ERROR, response);
          EventBus.$emit("UPDATE_EMPLOYEE_ERROR", true);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
