import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const STORE_MEMBER = "store-member";
export const UPDATE_MEMBER = "update-member";
export const DELETE_MEMBER = "delete-member";
export const STORE_UPGRADE_MEMBER = "store-upgrade-member";
export const UPDATE_UPGRADE_MEMBER = "update-upgrade-member";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  members: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {};

const actions = {
  [DELETE_MEMBER](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("member/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Member is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Member Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_MEMBER](context, member) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("member", { member })
        .then(({ data }) => {
          if (data.status == 0) {
            Swal.fire("Not Valid", data.message, "error");
            EventBus.$emit("STORE_MEMBER_BUS", false);
          } else {
            Swal.fire("Stored", "Member added successfully.", "success");
            EventBus.$emit("STORE_MEMBER_BUS", true);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Member Store", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_MEMBER](context, member) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("member/" + member.id, { member })
        .then(({ data }) => {
          Swal.fire("Updated", data.message, "success");
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Member Update", "error");
          context.commit(SET_ERROR, response);
          EventBus.$emit("UPDATE_MEMBER_ERROR", true);
        });
    });
  },
  [STORE_UPGRADE_MEMBER](context, member2) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("upgrade-downgrade-member", { member: member2})
        .then(({ data }) => {
          if (data.status == 0) {
            Swal.fire("Not Valid", data.message, "error");
            EventBus.$emit("STORE_MEMBER_BUS", false);
          } else {
            Swal.fire("Stored", "Member added successfully.", "success");
            EventBus.$emit("STORE_MEMBER_BUS", true);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Member Store", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_UPGRADE_MEMBER](context, member2) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("update-upgrade-downgrade-member", { member: member2})
        .then(({ data }) => {
          if (data.status == 0) {
            Swal.fire("Not Valid", data.message, "error");
            EventBus.$emit("STORE_MEMBER_BUS", false);
          } else {
            Swal.fire("Stored", "Member added successfully.", "success");
            EventBus.$emit("STORE_MEMBER_BUS", true);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Member Store", "error");
          context.commit(SET_ERROR, response);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
