import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import EventBus from "@/core/services/store/event-bus";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const GET_BUSINESS_ACCOUNTS = "get-business-account";
export const STORE_BUSINESS_ACCOUNT_TYPE = "store-business-account-type";
export const UPDATE_BUSINESS_ACCOUNT_TYPE = "update-business-account-type";
export const DELETE_BUSINESS_ACCOUNT_TYPE = "delete-business-account";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  business_account_type: {},
  isAuthenticated: !!JwtService.getToken(),
  duration: ""
};

const getters = {
  getBusinessAccountType(state) {
    return state.business_account_type;
  }
};

const actions = {
  [GET_BUSINESS_ACCOUNTS](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("business-account?search=''", "")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in fetch Business Account", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_BUSINESS_ACCOUNT_TYPE](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("business-account-types/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Business Account Type is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Business Account Type Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_BUSINESS_ACCOUNT_TYPE](context, business_account_type) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("business-account-types", { business_account_type })
        .then(({ data }) => {
          resolve(data);
          if (data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Stored", data.message, "success");
          }
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Business account type store", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_BUSINESS_ACCOUNT_TYPE](context, business_account_type) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("business-account-types/" + business_account_type.id, {
        business_account_type
      })
        .then(({ data }) => {
          resolve(data);
          if (data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Updated", data.message, "success");
          }
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Business Account Update", "error");
          context.commit(SET_ERROR, response);
          EventBus.$emit("UPDATE_BUSINESS_ACCOUNT_TYPE_ERROR", true);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
