import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        //Documents routes start
        {
          path: "/documents/create",
          name: "documents",
          component: () => import("@/view/pages/documents/DocumentForm.vue")
        },
        {
          path: "/documents",
          name: "documentsList",
          component: () => import("@/view/pages/documents/DocumentsList.vue")
        },
        {
          path: "/documents/:id/edit",
          name: "documents.edit",
          component: () => import("@/view/pages/documents/DocumentForm.vue")
        },
        {
          path: "/documents/:id/renew",
          name: "documents.renew",
          component: () =>
            import("@/view/pages/documents/DocumentRenewForm.vue")
        },
        //Documents routes end

        //Employees routes start
        {
          path: "/employees",
          name: "employeesList",
          component: () => import("@/view/pages/employees/EmployeesList.vue")
        },
        {
          path: "/employees/create",
          name: "employeesCreate",
          component: () => import("@/view/pages/employees/EmployeeForm.vue")
        },
        {
          path: "/employees/:id/edit",
          name: "employees.edit",
          component: () => import("@/view/pages/employees/EmployeeForm.vue")
        },
        //Employees routes end

        //Member routes start
        {
          path: "/members",
          name: "membersList",
          component: () => import("@/view/pages/members/MembersList.vue")
        },
        {
          path: "/members/create",
          name: "membersCreate",
          component: () => import("@/view/pages/members/MembersForm.vue")
        },
        {
          path: "/members/:id/edit",
          name: "members.edit",
          component: () => import("@/view/pages/members/MembersForm.vue")
        },
        {
          path: "/members/:id/manage",
          name: "members.manage",
          component: () => import("@/view/pages/members/MembersManage.vue")
        },
        {
          path: "/members/:id/manage/:committee_id",
          name: "members.tasks",
          component: () => import("@/view/pages/members/ViewTaskList.vue")
        },
        //Member routes end

        //Events routes start
        {
          path: "/events",
          name: "eventsList",
          component: () => import("@/view/pages/events/EventList.vue")
        },
        {
          path: "/events/create",
          name: "eventsCreate",
          component: () => import("@/view/pages/events/EventForm.vue")
        },
        {
          path: "/events/:id/edit",
          name: "events.edit",
          component: () => import("@/view/pages/events/EventForm.vue")
        },
        {
          path: "/events/:id/show",
          name: "events.show",
          component: () => import("@/view/pages/events/EventShow.vue")
        },
        {
          path: "/events/:id/view",
          name: "events.view",
          component: () => import("@/view/pages/events/EventView.vue")
        },
        //Events routes end

        //Business Account routes start
        {
          path: "/business-account",
          name: "businessAccountList",
          component: () => import("@/view/pages/business_account/BAList.vue")
        },
        {
          path: "/business-account/create",
          name: "businessAccountCreate",
          component: () => import("@/view/pages/business_account/BAForm.vue")
        },
        {
          path: "/business-account/:id/edit",
          name: "businessAccount.edit",
          component: () => import("@/view/pages/business_account/BAForm.vue")
        },
        {
          path: "/business-account/:id/show",
          name: "businessAccount.show",
          component: () => import("@/view/pages/business_account/BAShow.vue")
        },
        //Business Account routes end

        //Business Account Types routes start
        {
          path: "/business-account-type",
          name: "businessAccountTypeList",
          component: () => import("@/view/pages/business_account_types/BATList.vue")
        },
        {
          path: "/business-account-type/create",
          name: "businessAccountTypeCreate",
          component: () => import("@/view/pages/business_account_types/BATForm.vue")
        },
        {
          path: "/business-account-type/:id/edit",
          name: "businessAccountType.edit",
          component: () => import("@/view/pages/business_account_types/BATForm.vue")
        },
        {
          path: "/business-account-type/:id/show",
          name: "businessAccountType.show",
          component: () => import("@/view/pages/business_account_types/BATShow.vue")
        },
        //Business Account Types routes end

        //Document Types routes start
        {
          path: "/document-type",
          name: "documentTypeList",
          component: () => import("@/view/pages/document_types/DTList.vue")
        },
        {
          path: "/document-type/create",
          name: "documentTypeCreate",
          component: () => import("@/view/pages/document_types/DTForm.vue")
        },
        {
          path: "/document-type/:id/edit",
          name: "documentType.edit",
          component: () => import("@/view/pages/document_types/DTForm.vue")
        },
        {
          path: "/document-type/:id/show",
          name: "documentType.show",
          component: () => import("@/view/pages/document_types/DTShow.vue")
        },
        //Document Types routes end

        //Resource Types routes start
        {
          path: "/resource-type",
          name: "resourceTypeList",
          component: () => import("@/view/pages/resource_types/RTList.vue")
        },
        {
          path: "/resource-type/create",
          name: "resourceTypeCreate",
          component: () => import("@/view/pages/resource_types/RTForm.vue")
        },
        {
          path: "/resource-type/:id/edit",
          name: "resourceType.edit",
          component: () => import("@/view/pages/resource_types/RTForm.vue")
        },
        {
          path: "/resource-type/:id/show",
          name: "resourceType.show",
          component: () => import("@/view/pages/resource_types/RTShow.vue")
        },
        //Resource Types routes end

        //Resource routes start
        {
          path: "/resources",
          name: "resourceList",
          component: () => import("@/view/pages/resources/ResourceList.vue")
        },
        {
          path: "/resources/create",
          name: "resourceCreate",
          component: () => import("@/view/pages/resources/ResourceForm.vue")
        },
        {
          path: "/resources/:id/edit",
          name: "resource.edit",
          component: () => import("@/view/pages/resources/ResourceForm.vue")
        },
        //Resource routes end

        //Training Video routes start
        {
          path: "/trainingvideo/create",
          name: "trainingvideoCreate",
          component: () => import("@/view/pages/committee/TrainingVideo.vue")
        },
        {
          path: "/trainingvideo/list",
          name: "trainingvideoList",
          component: () => import("@/view/pages/committee/TrainingVideoList.vue")
        },
        {
          path: "/trainingvideo/:id/edit",
          name: "trainingvideo.edit",
          component: () => import("@/view/pages/committee/TrainingVideo.vue")
        },
        {
          path: "/committee/roles",
          name: "committee.roles",
          component: () => import("@/view/pages/committee/RolesList.vue")
        },
        {
          path: "/committee/roles/add",
          name: "committee.roles.add",
          component: () => import("@/view/pages/committee/RoleAdd.vue")
        },
        {
          path: "/committee/roles/:id/edit",
          name: "committee.roles.edit",
          component: () => import("@/view/pages/committee/RoleAdd.vue")
        },
        {
          path: "/committee/roles/:role_id/:type/taskcreate",
          name: "committee.roles.task.create",
          component: () => import("@/view/pages/committee/CreateTask.vue")
        },
        {
          path: "/permissions",
          name: "permissions",
          component: () => import("@/view/pages/permissions/Permissions.vue")
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/view/pages/settings/Settings.vue")
        },
        {
          path: "/mytasks",
          name: "mytasks",
          component: () => import("@/view/pages/committee/MyTasksList.vue")
        },
        //Training video routes end

        //Committee routes start
        {
          path: "/committee/list",
          name: "CommitteeList",
          component: () => import("@/view/pages/committee/CommitteeList.vue")
        },
        {
          path: "/committee/create",
          name: "committeeCreate",
          component: () => import("@/view/pages/committee/CommitteeForm.vue")
        },
        {
          path: "/committee/:id/edit",
          name: "committee.edit",
          component: () => import("@/view/pages/committee/CommitteeForm.vue")
        },
        //Committee routes end

        //Training routes start
        {
          path: "/training",
          name: "training.list",
          component: () => import("@/view/pages/training/TrainingList.vue")
        },
        {
          path: "/training-available",
          name: "training.available",
          component: () =>
            import("@/view/pages/training/AvailableTrainingList.vue")
        },
        {
          path: "/training/:id/enroll",
          name: "training.enroll",
          component: () => import("@/view/pages/training/EnrollTraining.vue")
        },
        //Training routes start

        //My Training routes start
        {
          path: "/my-trainings",
          name: "my-trainings.list",
          component: () => import("@/view/pages/my-trainings/TrainingList.vue")
        },
        {
          path: "/my-trainings/:id/view",
          name: "my-trainings.view",
          component: () => import("@/view/pages/my-trainings/TrainingView.vue")
        },
        //My Training routes end

        //Profile routes start
        {
          path: "/profile",
          name: "profile.edit",
          component: () => import("@/view/pages/profile/Profile.vue")
        },
        //Profile routes end

        //Reset password routes start
        {
          path: "/reset-password",
          name: "resetpassword.edit",
          component: () => import("@/view/pages/reset_password/ResetPassword.vue")
        },
        //Reset password routes end

        //Contact us routes start
        {
          path: "/contact-us",
          name: "countactus.form",
          component: () => import("@/view/pages/contact_us/ContactUsForm.vue")
        },
        //Contact us routes end

        //Notifications routes start
        {
          path: "/notifications",
          name: "notificationsList",
          component: () => import("@/view/pages/notifications/NotificationsList.vue")
        },
        //Notifications routes end

        //FAQs routes start
        {
          path: "/faq",
          name: "faq-list",
          component: () => import("@/view/pages/faq/FaqList.vue")
        },
        //FAQs routes end

        //contact routes start
        {
          path: "/contacts",
          name: "ContactList",
          component: () => import("@/view/pages/contacts/ContactList.vue")
        },
        {
          path: "/contact",
          name: "ContactCreate",
          component: () => import("@/view/pages/contacts/ContactForm.vue")
        },
        {
          path: "/contact/:id/edit",
          name: "contact.edit",
          component: () => import("@/view/pages/contacts/ContactForm.vue")
        },
        //contact routes end

        //assessment routes start
        {
          path: "/assessments",
          name: "AssessmentList",
          component: () => import("@/view/pages/assessment/AssessmentList.vue")
        },
        {
          path: "/viewactionitems",
          name: "ActionItems",
          component: () => import("@/view/pages/assessment/ActionItems.vue")
        },
        {
          path: "/assessment/:id/:attempt",
          name: "assessment.fill",
          component: () => import("@/view/pages/assessment/AssessmentFill.vue")
        },
        {
          path: "/assessment/:id/:attempt/:question_id",
          name: "assessment.restart",
          component: () =>
            import("@/view/pages/assessment/RestartAssessment.vue")
        },
        //assessment routes end

        //subscription routes start
        {
          path: "/subscription",
          name: "Subscription",
          component: () => import("@/view/pages/subscription/Subscription.vue")
        },
        //subscription routes start

        //Company Admin routes start
        {
          path: "/company-admin",
          name: "companyAdminList",
          component: () => import("@/view/pages/company_admins/companyAdminList.vue")
        },
        {
          path: "/company-admin/create",
          name: "companyAdminCreate",
          component: () => import("@/view/pages/company_admins/companyAdminForm.vue")
        },
        {
          path: "/company-admin/:id/edit",
          name: "companyAdmin.edit",
          component: () => import("@/view/pages/company_admins/companyAdminForm.vue")
        },
        //Company Admin routes end

        //Finance routes start
        {
          path: "/finance",
          name: "financeList",
          component: () => import("@/view/pages/finance/financeList.vue")
        }
        //Finance Admin routes end
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      name: "otp",
      path: "/send-otp",
      component: () => import("@/view/pages/auth/login_pages/Otp"),
      props: (route) => ({ email: route.params.email, password: route.params.password, company_type: route.params.company_type }),
      beforeEnter: (to, from, next) => {
        const isParamsAvailable = to.params.email && to.params.password && to.params.company_type;
        if (isParamsAvailable) {
          next();
        }
        else {
          next({name: "login"});
        }
      }
    },
    {
      path: "/welcome/onboarding/:user_id",
      name: "onboarding",
      component: () => import("@/view/pages/auth/OnBoarding")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    },
    {
      name: "reset-password",
      path: "/reset-password/:token_id",
      component: () => import("@/view/pages/auth/ResetPassword")
    },


    // guest user assessment routes start
    {
      path: "/",
      component: () => import("@/view/guest_layout/Layout"),
      children: [
        {
          path: "/guest-assessment/:id/:attempt",
          name: "guest-assessment.fill",
          component: () => import("@/view/pages/guest_assessment/GuestAssessmentFill.vue")
        }
      ]  
    }
    // guest user assessment routes end
  ]
});
