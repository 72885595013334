import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init(url) {
    Vue.use(VueAxios, axios);
    if (url == "auth") {
      Vue.axios.defaults.baseURL = process.env.VUE_APP_ACAUTH_URL;
    } else {

      Vue.axios.defaults.baseURL = process.env.VUE_APP_ACBACK_URL;
    }
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    let jwt_token = JwtService.getToken();
    if (jwt_token.includes("Bearer")) {
      // JwtService.saveToken(jwt_token.slice(6, jwt_token.length));
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `${JwtService.getToken()}`;
    } else {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;
    }
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    // let base_url = "http://localhost/back/public/api/";
    // return Vue.axios.post(base_url + resource, params);
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    console.log(resource)
    console.log(params)
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
