import ApiService from "@/core/services/api.service";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";

// action types
export const STORE_EVENT = "store-event";
export const MAKE_READ = "make-read";
export const UPDATE_EVENT = "update-event";
export const DELETE_EVENT = "delete-event";
export const AVAILABLE_FOR_EVENT = "available-for-event";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  event: null
};

const getters = {};

const actions = {
  [AVAILABLE_FOR_EVENT](context, event) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("event/add-attendee", event)
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("AVAILABLE_FOR_EVENT", true);
        })
        .catch(() => {
          EventBus.$emit("AVAILABLE_FOR_EVENT", false);
        });
    });
  },
  [MAKE_READ](context, datas) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("read-notification", datas)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {
          Swal.fire("Error", "Error in read notification.", "error");
        });
    });
  },
  [STORE_EVENT](context, event) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("events", event)
        .then(({ data }) => {
          resolve(data);
          if(data.status) {
            EventBus.$emit("STORE_EVENT", true);
            Swal.fire("Success", data.message, "success");
          } else {
            Swal.fire("Error", data.message, "error");
          }
        })
        .catch(() => {
          EventBus.$emit("STORE_EVENT", false);
        });
    });
  },
  [UPDATE_EVENT](context, event) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("events/" + event.id, event)
        .then(({ data }) => {
          resolve(data);
          if(data.status) {
            EventBus.$emit("STORE_EVENT", true);
            Swal.fire("Success", data.message, "success");
          } else {
            Swal.fire("Error", data.message, "error");
          }
        })
        .catch(() => {
          EventBus.$emit("STORE_EVENT", false);
          Swal.fire("Error", "Error in Event Update", "error");
          EventBus.$emit("UPDATE_EVENT_ERROR", true);
        });
    });
  },
  [DELETE_EVENT](context, event_id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("events/" + event_id)
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Event is Deleted!", "", "success");
          EventBus.$emit("DELETE_EVENT", true);
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          EventBus.$emit("DELETE_EVENT", false);
          Swal.fire("Error", "Error in Event Delete", "error");
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
