import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import EventBus from "@/core/services/store/event-bus";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const GET_DOCUMENT_TYPES = "get-document-type";
export const STORE_DOCUMENT_TYPE = "store-document-type";
export const UPDATE_DOCUMENT_TYPE = "update-document-type";
export const DELETE_DOCUMENT_TYPE = "delete-document-type";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  document_type: {},
  isAuthenticated: !!JwtService.getToken(),
  duration: ""
};

const getters = {
  getDocumentType(state) {
    return state.document_type;
  }
};

const actions = {
  [GET_DOCUMENT_TYPES](context) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.get("document-type?search=''", "")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in fetch Document", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_DOCUMENT_TYPE](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("document-types/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Document Type is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Document Type Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_DOCUMENT_TYPE](context, document_type) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("document-types", { document_type })
        .then(({ data }) => {
          resolve(data);
          if (data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Stored", data.message, "success");
          }
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Document Type store", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_DOCUMENT_TYPE](context, document_type) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("document-types/" + document_type.id, {
        document_type
      })
        .then(({ data }) => {
          resolve(data);
          if (data.status == 0) {
            Swal.fire("Error", data.message, "error");
          } else {
            Swal.fire("Updated", data.message, "success");
          }
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Document Type Update", "error");
          context.commit(SET_ERROR, response);
          EventBus.$emit("UPDATE_DOCUMENT_TYPE_ERROR", true);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
