import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EventBus from "@/core/services/store/event-bus";
// import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const STORE_CONTACT_US = "store-contact-us";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  contact: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {};

const actions = {
  [STORE_CONTACT_US](context, contact) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("contact-us", contact)
        .then(({ data }) => {
          resolve(data);
          EventBus.$emit("STORE_CONTACT_US", true);
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          EventBus.$emit("STORE_CONTACT_US", true);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
