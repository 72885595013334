import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import EventBus from "@/core/services/store/event-bus";
import Swal from "sweetalert2";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const STORE_ADMIN = "store-admin";
export const UPDATE_ADMIN = "update-admin";
export const DELETE_ADMIN = "delete-admin";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {};

const actions = {
  [DELETE_ADMIN](context, id) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.delete("company-admin/" + id, {})
        .then(({ data }) => {
          resolve(data);
          Swal.fire("Company Admin is Deleted!", "", "success");
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Company Admin Delete", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_ADMIN](context, company_admin) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.post("company-admin", { company_admin })
        .then(({ data }) => {
          if (data.status == 0) {
            Swal.fire("Not Valid", data.message, "error");
            EventBus.$emit("STORE_ADMIN_BUS", false);
          } else {
            Swal.fire("Stored", "Company Admin added successfully.", "success");
            EventBus.$emit("STORE_ADMIN_BUS", true);
          }
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Company Admin Store", "error");
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_ADMIN](context, company_admin) {
    return new Promise(resolve => {
      ApiService.init("askcoda");
      ApiService.setHeader();
      ApiService.put("company-admin/" + company_admin.id, { company_admin })
        .then(({ data }) => {
          Swal.fire("Updated", data.message, "success");
          resolve(data);
        })
        .catch(({ response }) => {
          Swal.fire("Error", "Error in Company Admin Update", "error");
          context.commit(SET_ERROR, response);
          EventBus.$emit("UPDATE_ADMIN_ERROR", true);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};
