import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import user from "./user.module";
import tag from "./tag.module";
import document from "./document.module";
import employee from "./employee.module";
import member from "./member.module";
import businessaccount from "./businessaccount.module";
import businessaccounttype from "./businessaccounttype.module";
import documenttype from "./documenttype.module";
import resourcetype from "./resourcetype.module";
import resource from "./resource.module";
import contactus from "./contactus.module";
import contact from "./contact.module";
import assessment from "./assessment.module";
import event from "./event.module";
import training from "./training.module";
import committee from "./committee.module";
import companyadmin from "./companyadmin.module";
import guestassessment from "./guestassessment.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    user,
    tag,
    document,
    employee,
    member,
    businessaccount,
    businessaccounttype,
    documenttype,
    resourcetype,
    resource,
    contactus,
    contact,
    assessment,
    event,
    training,
    committee,
    companyadmin,
    guestassessment
  }
});
